import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

import PPID from "./../components/layanan_publik/PPID";
import DiktarIP from "./../components/layanan_publik/DiktarIP";
import KeberatanAtasInformasi from "./../components/layanan_publik/KeberatanAtasInformasi";
import LaporanTahunanPelayananIP from "./../components/layanan_publik/LaporanTahunanPelayananIP";
import LPIK from "./../components/layanan_publik/LPIK";
import MaklumatPelayanan from "./../components/layanan_publik/MaklumatPelayanan";
import PengaduanMasyarakat from "./../components/layanan_publik/PengaduanMasyarakat";
import PengenaanBiayaPerolehanIP from "./../components/layanan_publik/PengenaanBiayaPerolehanIP";
import PeraturanIP from "./../components/layanan_publik/PeraturanIP";
import RingkasanPelayananIP from "./../components/layanan_publik/RingkasanPelayananIP";
import SengketaIP from "./../components/layanan_publik/SengketaIP";
import SurveiKepuasan from "./../components/layanan_publik/SurveiKepuasan";
import AlurPelayanan from "./../components/layanan_pik/AlurPelayanan";
import FormKeberatanAtasInformasi from "./../components/layanan_pik/FormKeberatanAtasInformasi";
import FormPengaduan from "./../components/layanan_pik/FormPengaduan";
import FormPermintaanInformasi from "./../components/layanan_pik/FormPermintaanInformasi";
import About from "./../components/other/About";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/alur-pelayanan",
    name: "alur-pelayanan",
    component: AlurPelayanan,
  },
  {
    path: "/form-keberatan-atas-informasi",
    name: "form-keberatan-atas-informasi",
    component: FormKeberatanAtasInformasi,
  },
  {
    path: "/form-pengaduan",
    name: "form-pengaduan",
    component: FormPengaduan,
  },
  {
    path: "/form-permintaan-informasi",
    name: "form-permintaan-informasi",
    component: FormPermintaanInformasi,
  },
  {
    path: "/diktar-ip",
    name: "diktar-ip",
    component: DiktarIP,
  },
  {
    path: "/keberatan-atas-informasi",
    name: "keberatan-atas-informasi",
    component: KeberatanAtasInformasi,
  },
  {
    path: "/laporan-tahunan-pelayanan-ip",
    name: "laporan-tahunan-pelayanan-ip",
    component: LaporanTahunanPelayananIP,
  },
  {
    path: "/lpik",
    name: "lpik",
    component: LPIK,
  },
  {
    path: "/maklumat-pelayanan",
    name: "maklumat-pelayanan",
    component: MaklumatPelayanan,
  },
  {
    path: "/pengaduan-masyarakat",
    name: "pengaduan-masyarakat",
    component: PengaduanMasyarakat,
  },
  {
    path: "/pengenaan-biaya-perolehan-ip",
    name: "pengenaan-biaya-perolehan-ip",
    component: PengenaanBiayaPerolehanIP,
  },
  {
    path: "/peraturan-ip",
    name: "peraturan-ip",
    component: PeraturanIP,
  },
  {
    path: "/ppid",
    name: "ppid",
    component: PPID,
  },
  {
    path: "/ringkasan-pelayanan-ip",
    name: "ringkasan-pelayanan-ip",
    component: RingkasanPelayananIP,
  },
  {
    path: "/sengketa-ip",
    name: "sengketa-ip",
    component: SengketaIP,
  },
  {
    path: "/survei-kepuasan",
    name: "survei-kepuasan",
    component: SurveiKepuasan,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: About,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
