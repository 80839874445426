<template>
  <v-container style="min-height: 70vh">
      <v-card>
          <v-card-text>AlurPelayanan</v-card-text>
      </v-card>
  </v-container>
</template>
<script>
export default {
  name: "AlurPelayanan",
  data() {
    return {
        
    };
  },
  methods: {},
  computed: {},
  mounted() {},
  created() {},
};
</script>
