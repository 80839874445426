<template>
  <!-- <v-container style="min-height: 70vh"> -->
  <v-carousel height="90vh">
    <v-carousel-item v-for="(item, i) in images" :key="i" :src="item.src">
      <v-img
        :src="require('../assets/logo.png')"
        class="ml-3 mt-3"
        height="15vh"
        width="20vh"
      />
      <h3 class="ml-5 white--text image-description" style="margin-top: 60vh">
        {{ item.text }}
      </h3>
    </v-carousel-item>
  </v-carousel>
  <!-- </v-container> -->
</template>
<script>
export default {
  name: "HelloWorld",

  data: () => ({
    images: [
      {
        text: "BPS Provinsi Kalimantan Tengah",
        src: "https://mapio.net/images-p/44569369.jpg",
      },
      {
        text: "Susenas",
        src: "https://cdn.antaranews.com/cache/800x533/2021/07/15/Kepala-BPS-Kalteng-Eko-Marsoro.jpg",
      },
      {
        text: "Sakernas",
        src: "https://mmc.kalteng.go.id/files/berita/02122019124004_1.jpeg",
      },
    ],
  }),
};
</script>
