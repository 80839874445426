<template>
  <v-app id="body">
    <v-app-bar color="blue lighten-1" dense dark>
      <!-- <v-app-bar-nav-icon>
        <v-icon>
          <v-img :src="require('./assets/logo.png')" />
        </v-icon>
      </v-app-bar-nav-icon> -->
      <v-img
        class="mx-2"
        :src="require('./assets/logo.png')"
        max-height="40"
        max-width="40"
        contain
      ></v-img>
      <v-toolbar-title>PPID BPS Provinsi Kalimantan Tengah</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text color="white" dark to="/"> Home </v-btn>
      <div class="text-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text color="white" dark v-bind="attrs" v-on="on">
              Layanan Publik <v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item to="/ppid">
              <v-list-item-title>
                Pejabat Pengelola Informasi dan Dokumentasi
              </v-list-item-title>
            </v-list-item>
            <v-list-item to="/lpik">
              <v-list-item-title>
                Layanan Pusat Informasi dan Komunikasi
              </v-list-item-title>
            </v-list-item>
            <v-list-item to="/diktar-ip">
              <v-list-item-title>
                Diktar Informasi Publik
              </v-list-item-title>
            </v-list-item>
            <v-list-item to="/maklumat-pelayanan">
              <v-list-item-title>
                Maklumat Pelayanan
              </v-list-item-title>
            </v-list-item>
            <v-list-item to="/pengenaan-biaya-perolehan-ip">
              <v-list-item-title>
                Pengenaan Biaya Perolehan Informasi Publik
              </v-list-item-title>
            </v-list-item>
            <v-list-item to="/pengaduan-masyarakat">
              <v-list-item-title>
                Pengaduan Masyarakat
              </v-list-item-title>
            </v-list-item>
            <v-list-item to="/keberatan-atas-informasi">
              <v-list-item-title>
                Keberatan Atas Informasi
              </v-list-item-title>
            </v-list-item>
            <v-list-item to="/sengketa-ip">
              <v-list-item-title>
                Sengketa Informasi Publik
              </v-list-item-title>
            </v-list-item>
            <v-list-item to="/peraturan-ip">
              <v-list-item-title>
                Peraturan terkait Informasi Publik
              </v-list-item-title>
            </v-list-item>
            <v-list-item to="/ringkasan-pelayanan-ip">
              <v-list-item-title>
                Ringkasan Pelayanan Informasi Publik
              </v-list-item-title>
            </v-list-item>
            <v-list-item to="/laporan-tahunan-pelayanan-ip">
              <v-list-item-title>
                Laporan Tahunan Pelayanan Informasi Publik
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title key="/survei-kepuasan">
                Hasil Survei Pelayanan Kehumasan
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div class="text-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text color="white" dark v-bind="attrs" v-on="on">
              Layanan PIK <v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item to="/alur-pelayanan">
              <v-list-item-title>Alur Layanan</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item to="/form-permintaan-informasi">
              <v-list-item-title>Form Permintaan Informasi</v-list-item-title>
            </v-list-item>
            <v-list-item to="/form-pengaduan">
              <v-list-item-title>Form Pengaduan</v-list-item-title>
            </v-list-item>
            <v-list-item to="/form-keberatan-atas-informasi">
              <v-list-item-title>
                Form Keberatan Atas Informasi
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <v-footer dark padless>
      <v-card
        flat
        tile
        class="blue lighten-1 white--text text-center"
        style="min-width: 100vw"
      >
        <v-card-text>
          <v-btn
            v-for="icon in icons"
            :key="icon"
            class="mx-4 my-0 white--text"
            icon
          >
            <v-icon size="24px">
              {{ icon }}
            </v-icon>
          </v-btn>
        </v-card-text>

        <v-card-text class="white--text my-0">
          Jl. Kapten Piere Tendean No.6, Palangka, Kec. Jekan Raya, Kota Palangka Raya, Kalimantan Tengah 74874
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>BPS Provinsi Kalimantan Tengah</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
  }),
};
</script>
<style>
#body {
  background-image: url("./../src/assets/bg.jpg");
}
</style>
